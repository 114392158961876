import React from 'react';
import Heading from '../components/atoms/Heading';
import Text from '../components/atoms/Text';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const AboutPage = () => (
  <Layout>
    <SEO title="About Me" />
    <Heading fontSize="4xl">Andreas Raduege</Heading>
    <Text mt={1} mb={8}>
      he/him/his
    </Text>
    <Text mb={8}>
      I am an Application Developer who specializes in mobile application development using React
      Native. I&apos;m currently based out of Minneapolis, MN.
    </Text>
    <Text mb={8}>
      I currently work as the founder and lead developer of Nordstar Consulting, where I make apps
      for a wide swath of businesses. Before that, I was worked with gnomi to create a platform for
      ranking media bias in the news.
    </Text>
    <Text mb={8}>
      I graduated from St. Olaf College where I studied Physics and Computer Science. My physics
      research was in computational modeling of snow particles to predict albedo. My CS research
      studied the replicability of machine-learning papers in computer science academia.
    </Text>
    <Text mb={8}>
      Other things I enjoy include craft cocktails, swing dancing, and getting way too involved in
      side projects.
    </Text>
  </Layout>
);

export default AboutPage;
