import styled from 'styled-components';
import { BoxProps, styledProps } from './Box';

const Heading = styled.h1<BoxProps>`
  font-family: ${props => props.theme.fonts.heading};
  letter-spacing: 1px;
  font-size: ${props => props.theme.fontSizes['3xl']};
  margin: 0;
  padding: 0;
  ${styledProps}
`;

export default Heading;
