import styled from 'styled-components';
import { BoxProps, styledProps } from './Box';

const Text = styled.p<BoxProps>`
  margin: 0;
  padding: 0;
  font-size: ${props => props.theme.fontSizes.md};
  ${styledProps}
`;

export default Text;
